@font-face {
  font-family: 'Metropolis';
  src: url("../assets/fonts/Metropolis-Regular.otf"), url("../assets/fonts/Metropolis-SemiBold.otf")
}



$bg: #FFFFFF;
$bg2: #40A7FF00;
$primary: #2699FB;
$secondary: #BCE0FD;
$third: #7B7B7B;
$success: #2BAC18;

$font-family: "Metropolis";
$backgroundImage: -webkit-linear-gradient( 90deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.2) 100%);

@function get-vw($target, $orientation) {
  $vw-context: (256*.01) * 1px;
  @if ($orientation == "landscape") {
    $vw-context: (720*.01) * 1px;
  }
  @return ($target/$vw-context) * 1vw;
}

@function get-vh($target, $orientation) {
  $vh-context: ((680*.01)+ ((680*.01) * 0.06)) * 1px;
  @if ($orientation == "landscape") {
    $vh-context: (269*.01) * 1px;
  }
  @return ($target/$vh-context) * 1vh;
}


@mixin font($size) {
  font-size: $size;
  @for $i from 1 through 24 {
    @media screen and (min-height:#{680 + ($i * 50)}px) and (orientation: portrait) {
      font-size: calc(#{$size} + #{$i/2}px);
    }
    @media screen and (min-width:#{256 + ($i * 50)}px) and (orientation: portrait){
      font-size: calc(#{$size} + #{$i/2}px);
    }
    @media screen and (min-height:#{269 + ($i * 50)}px) and (orientation: landscape) {
      font-size: calc(#{$size} + #{$i/2}px);
    }
    @media screen and (min-width:#{720 + ($i * 50)}px) and (orientation: landscape){
      font-size: calc(#{$size} + #{$i/2}px);
    }
  }
}