@import "@constants/constants";
.icon{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: get-vw(22px, "portrait");
  min-width: get-vw(22px, "portrait");
  @media only screen and (orientation: landscape) {
    width: get-vw(22px, "landscape");
    min-width: get-vw(22px, "landscape");
  }
  .image{
    max-width: 100%;
    height: get-vh(22px,"portrait");
    min-height: get-vh(22px,"portrait");
    @media only screen and (orientation: landscape) {
      height: get-vh(22px,"landscape");
      min-height: get-vh(22px,"landscape");
    }
  }
}

.smallIcon{
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: get-vw(19px, "portrait");
  min-width: get-vw(19px, "portrait");
  @media only screen and (orientation: landscape) {
    width: get-vw(19px, "landscape");
    min-width: get-vw(19px, "landscape");
  }
  .smallImage{
    max-width: 100%;
    height: get-vh(17px,"portrait");
    min-height: get-vh(17px,"portrait");
    @media only screen and (orientation: landscape) {
      height: get-vh(17px,"landscape");
      min-height: get-vh(17px,"landscape");
    }
  }
}