@import "@constants/constants";

.app{
  position: fixed;
  background: transparent;
  border-radius: 2px;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  @media screen and (orientation: portrait) {
    height: calc(100vh - 6%);
    bottom: 6%;
  }
  overflow: hidden;
  h3{
    color: white;
    @include font(14px);
    padding: 0;
    margin: 0;
    justify-self: flex-end;
    align-self: flex-end;
    text-transform: uppercase;
    font-family: $font-family;
    font-weight: normal;
    max-width: 100%;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
  }
  h2{
    color: white;
    @include font(16px);
    padding: 0;
    margin: 0;
    justify-self: flex-end;
    align-self: start;
    text-transform: uppercase;
    font-family: $font-family;
    font-weight: bold;
    max-width: 100%;
    height: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
    
  }
  h1{
    color: white;
    @include font(22px);
    padding: 0;
    margin: 0;
    max-width: 100%;
    height: auto;
    align-self: center;
    font-family: $font-family;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: hidden;
  }
  span{
    @include font(14px);
    color: white;
    font-family: $font-family;
    font-weight: normal;
    padding: 0;
    margin: 0;
  }
}

