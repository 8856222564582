@import "@constants/constants";
.routeItem{
  box-sizing: border-box;
  padding: get-vh(5px, "portrait") get-vw(7px, "portrait");
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: get-vh(56.6px, "portrait");
  max-height: get-vh(56.6px, "portrait");
  @media only screen and (orientation: landscape) {
    height: get-vh(65px, "landscape");;
    max-height: get-vh(65px,"landscape");
    padding: get-vh(5px, "landscape") get-vw(7px, "landscape");
  }
  .info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    h1 {
      margin-left: get-vw(10px, "portrait");
      margin-right: get-vw(10px, "portrait");
      @media only screen and (orientation: landscape) {
        margin-right: get-vw(10px, "landscape");
        margin-left: get-vw(10px, "landscape");
      }
      &:last-child {
        margin-left: auto;
        margin-right: 0;
        word-wrap: break-word;
        text-overflow: unset;
        overflow: unset;
      }
    }
    .longName{
      @include font(20px)
    }
  }
}
