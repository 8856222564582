@import "@constants/constants";
.page{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  @media only screen and (orientation: landscape) {
    margin: 0 get-vw(2.5px, "landscape");
    width: get-vw(234.5px, "landscape");
    height: 100%;
  }
  .header{
    box-sizing: border-box;
    padding: get-vh(5px,"portrait") get-vw(7px, "portrait");
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: get-vh(56.6px, "portrait");
    max-height: get-vh(56.6px, "portrait");
    @media only screen and (orientation: landscape) {
      padding: get-vh(5px,"landscape") get-vw(7px, "landscape");
      height: get-vh(61px, "landscape");
      max-height: get-vh(61px, "landscape");
    }
    background-color: rgba(123,123,123,0.5);
    .info{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;
      width: 100%;
      height: 100%;
      span{
        &:last-child {
          margin-left: auto;
        }
      }
    }
  }
  .items{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    .noRoute{
        box-sizing: border-box;
        padding: get-vh(5px,"portrait") get-vw(7px,"portrait");
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: get-vh(56.6px,"portrait");
        max-height: get-vh(56.6px,"portrait");
        @media only screen and (orientation: landscape) {
          padding: get-vh(5px,"landscape") get-vw(7px,"landscape");
          height: get-vh(65px,"landscape");
          max-height: get-vh(65px,"landscape");
        }
        h3{
          display: flex;
          align-items: center;
          color: white;
          text-overflow: unset;
          white-space: pre-wrap;
          text-align: start;
          height: 50%;
          line-height: get-vh(14px,"portrait");
          @media only screen and (orientation: landscape) {
            line-height: get-vh(14px,"landscape");
          }
        }
    }
  }
}
