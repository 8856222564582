.page{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  max-width: 100%;
  .routes{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    @media only screen and (orientation: landscape) {
      flex-direction: row;
      justify-content: center;
      height: 100%;
    }
  }
}
